const headers = [
  {
    text: 'Nombre Completo',
    value: 'name',
    sortable: false
  },
  {
    text: 'Cargo',
    value: 'attendeePosition',
    sortable: false
  },
  {
    text: 'Empresa',
    value: 'companyName',
    sortable: false
  },
  {
    text: 'Usuario Expositor',
    value: 'expositorUser',
    sortable: false
  },
  {
    text: 'Compañía expositor',
    value: 'expositorCompany',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '20%'
  }
]
export default headers
