<template>
  <div>
    <banner
      :title="`${user.fairName} - Administrador Expositor - ${user.companyName}`"
      type="banner--admin"
      :options="{padding: '30px'}">
    </banner>
    <div class="pt-3 px-7">
      <div class="row">
        <div class="col-12 d-flex align-center justify-space-between">
          <h2>Visitantes Registrados</h2>
          <v-btn
            color="primary"
            outlined
            class="text--primary text-none elevation-0"
            @click="exportExcel()">
            <v-icon left dark>
              fa-file-excel
            </v-icon>
            Exportar
          </v-btn>
        </div>
      </div>
      <v-row>
        <v-col cols="12">
          <dashboard-card
            icon="account-search-outline"
            text="Visitantes Escaneados"
            :title="scannedVisitors"
            color="primary"
          ></dashboard-card>
          <dashboard-card class="ml-5"
                          icon="account-group"
                          text="Visitantes Únicos Escaneados"
                          :title="scannedUniqueVisitors"
                          color="secondary"
          ></dashboard-card>
        </v-col>
      </v-row>
    </div>
    <div class="px-7">
      <div class="row">
        <div class="col-6">
          <v-text-field
            v-model="params.filterName"
            label="Nombre Completo"
            v-on:keyup.enter="search"
            outlined
            dense
            single-line
            hide-details
          ></v-text-field>
        </div>
        <div class="col-6">
          <v-select
            v-model="params.filterCompany"
            :items="companies"
            item-text="companyName"
            item-value="companyName"
            outlined
            dense
            label="Empresa"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <v-select
            v-model="params.filterExpositor"
            :items="expositors"
            item-text="username"
            item-value="id"
            outlined
            dense
            label="Usuario Expositor"
          ></v-select>
        </div>
        <div class="col-6">
          <v-select
            v-model="params.filterPosition"
            :items="positions"
            item-text="companyPosition"
            item-value="companyPosition"
            outlined
            dense
            label="Cargo"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-end">
          <v-btn
            color="primary"
            @click="search"
            class="white--text text-none elevation-0 mr-2">
            <v-icon
              left
              dark>
              fa-search
            </v-icon>
            Buscar
          </v-btn>
          <v-btn
            color="default"
            @click="clean"
            class="text-none elevation-0">
            <v-icon
              left
              dark>
              fa-broom
            </v-icon>
            Limpiar
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table ref="visitors" :url="url" :params="params" :headers="headers" :mobile="true">
            <template v-slot:item.name="{ item }">
              {{ item.name.toUpperCase()}}
            </template>
            <template v-slot:item.attendeePosition="{ item }">
              {{ item.attendeePosition.toUpperCase()}}
            </template>
            <template v-slot:item.companyName="{ item }">
              {{ item.companyName.toUpperCase()}}
            </template>
            <template v-slot:item.expositorCompany="{ item }">
              {{ item.expositorCompany.toUpperCase()}}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="nVisitorModal(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      dark
                      small
                    >
                      fa fa-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver mas</span>
              </v-tooltip>
            </template>
          </data-table>
        </div>
      </div>
      <visitor-modal ref="visitorModal" @onClose="onClose"/>
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/data-table/DataTable'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import {
  VISITORS_URL,
  DATA_TO_FILTER_VISITORS_URL,
  EXPORT_VISITORS_URL,
  COUNT_VISITORS_URL
} from '@/constants/ServicesMobileConstants'
import headers from '@/views/private/visitors/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import DashboardCard from '@/components/dashboard-card/DashboardCard.vue'
import VisitorModal from '@/views/private/visitors/components/VisitorModal'
import Banner from '@/components/banner/Banner'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperExpositorAdmin(user.roleId) || sessionMixin.methods.isExpositorAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    Banner,
    DataTable,
    VisitorModal,
    DashboardCard
  },
  data () {
    return {
      headers,
      url: VISITORS_URL,
      params: {
        filterName: '',
        filterCompany: '',
        filterExpositor: '',
        filterPosition: ''
      },
      companies: [],
      expositors: [],
      positions: [],
      scannedVisitors: '',
      scannedUniqueVisitors: '',
      selectedUser: {},
      user: null
    }
  },
  methods: {
    clean () {
      this.params.filterName = ''
      this.params.filterCompany = ''
      this.params.filterExpositor = ''
      this.params.filterPosition = ''
      this.search()
    },
    async getCountVisitors () {
      const response = await this.getMobile(`${COUNT_VISITORS_URL}/${this.user.fairId}`)
      this.scannedVisitors = String(response.data.scannedVisitors)
      this.scannedUniqueVisitors = String(response.data.scannedUniqueVisitors)
    },
    async getDataToFilterVisitors () {
      const response = await this.postMobile(DATA_TO_FILTER_VISITORS_URL)
      this.companies = response.data.companies
      this.expositors = response.data.expositors
      this.positions = response.data.positions
    },
    exportExcel () {
      this.showLoader()
      const user = sessionMixin.methods.getUser()
      this.$axiosApiMobile.get(EXPORT_VISITORS_URL, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `visitantes_${user.fairName}_${user.companyName}.xlsx`)
          document.body.appendChild(link)
          this.preload = false
          link.click()
          this.hideLoader()
        }).catch((error) => {
          this.showError(error)
          this.hideLoader()
        })
    },
    onClose () {
      this.$refs.visitors.getDataFromApi()
    },
    nVisitorModal (item) {
      this.$refs.visitorModal.open(item)
    },
    search () {
      this.$refs.visitors.getDataFromApi()
    }
  },
  mixins: [
    crudServiceMobileMixin,
    loaderMixin,
    notificationMixin,
    sessionMixin
  ],
  name: 'Visitors',
  async created () {
    this.user = JSON.parse(localStorage.getItem('userInfo')).user
    this.url = `${VISITORS_URL}/${this.user.fairId}`
    try {
      this.showLoader()
      await this.getCountVisitors()
      await this.getDataToFilterVisitors()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  }
}
</script>
<style scoped>
.custom-col {
  padding: 0px !important;
}

.v-card {
  display: inline-block;
}
</style>
